@tailwind base;
@tailwind components;
@tailwind utilities;

article h1 {
	@apply text-xl font-medium mb-3;
}

article h2 {
	@apply font-medium mb-3;
}

article p {
	@apply leading-snug mb-1;
}

article ul {
	@apply mb-1;
}

article li {
	@apply ml-6 list-disc;
}
